import React, { useState } from 'react';
import { Route  } from 'react-router-dom';
import Container from '@mui/material/Container';
import Loader from './Loader';
import Home from './Home';

const Section = () => {
    return (
        // <Container className="fixed-container" style={{marginTop:"140px", padding: "0 40px", minHeight: "calc( 100vh - 594px )"}}>
        <div style={{marginTop: 82, minHeight: "calc( 100vh - 594px )"}}>
            {/* <Loader loading={loading}/> */}
            <Route path="/" component={Home} exact/>
        </div>
    );
}

export default Section;

