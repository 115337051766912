import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DiscussionEmbed } from 'disqus-react';
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import React, { useEffect, useRef, useState } from 'react';
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import ReactPlayer from 'react-player/youtube';

const disqusShortname = "https-www-kafink-org"
const disqusConfig = {
    url: "https://www.kafink.org/",
    identifier: "article-id",
    title: "Leave a message"
}

const list1 = [
    {
        title: "Knowledge exchange",
        titleColor: "#1d1d1b",
        image: "https://www.opromouthguards.com/media/magefan_blog/OPRO1-amfoot.jpg",
    },
    {
        title: "Knowledge exchange",
        titleColor: "#1d1d1b",
        image: "https://www.opromouthguards.com/media/magefan_blog/OPRO1-amfoot.jpg",
    },
    {
        title: "Knowledge exchange",
        titleColor: "#1d1d1b",
        image: "https://www.opromouthguards.com/media/magefan_blog/OPRO1-amfoot.jpg",
    },
]

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        textAlign: "left",
    },    
    donate: {
        background: "#da291c",
        color: "#fff",
        padding: "10px 20px",
        marginRight: "30px",
        fontSize: 22,
        borderRadius: 10,
        fontWeight: "bold",
    },    
    // flex: {
    //     [theme.breakpoints.up('md')]: {
    //         display: 'flex'
    //     }
    // },
    mainContainer2: {
        backgroundColor: "#3c9dcd",
        minHeight: 500,
        padding: "20px",
    },
    mainContainer: {
        backgroundImage: "url('/bg_top1.jpg')",
        backgroundSize: "cover",
        minHeight: 650,
    },
    main: {
        marginTop: 50,
        padding: '30px 40px',
        minHeight: 300,
        background: '#fff',
        borderRadius: 20,
        position: 'relative',
    },
    kafink: {
        '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            background: '#0c577b',
            width: '20px',
            height: '120px',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            background: '#3c9dcd',
            width: '120px',
            height: '20px',
        },        
    },
    kafink_content: {
        "& .notion-text": {
            color: '#605a5a',
        }
    },
    ribbonContainer: {
        position: 'relative',
        width: "240px",
    },
    ribbon: {
        backgroundColor: '#f20e15',
        color: 'white',
        width: '200px',
        padding: '3px 20px',
        fontSize: "20px",
        fontWeight: "500",
        
        '&::before': {
            content: '""',
            position: 'absolute',
            borderTopColor: "#f20e15",
            borderBottomColor: "#f20e15",
            right: "-12px",
            borderColor: "currentcolor transparent",
            borderStyle: "solid",
            borderWidth: "0 12px 30px 8px",
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            borderTopColor: "#8c0018",
            borderBottomColor: "#8c0018",
            zIndex: -1,
            left: 0,
            top: "28px",
            borderColor: "currentcolor transparent",
            borderStyle: "solid",
            borderWidth: "0 7px 13px 8px",
            transform: "rotate(-90deg)",
        },    
    },
    cardContainer: {
        '& .MuiCard-root': {
            height: "100%",
        },
        '& .MuiCardActions-spacing': {
            display: "none",
        }
    },
    btn: {
        fontSize: "10px",
        height: "40px",
        margin: "10px!important",
    },
    titleContainer: {
        fontSize: "25px",
        margin: "50px 0 20px",
        borderBottom: "1px solid #0a88c6",
    },
    title1: {
        background: "#034565",
        color: "#fff",
        maxWidth: 250,
        borderRadius: 10,
        padding: "5px 20px",
        fontSize: 16,
        fontWeight: "bold",
    },
    title: {
        borderBottom: "5px solid #0a88c6",
        width: "250px",
        fontSize: 20,
    }
}));

const Home = () => {
    const classes = useStyles();
    const [intro, setIntro] = useState({});
    const [donation, setDonation] = useState({});
    const [listAds, setListAds] = useState([]);
    const [listNews, setListNews] = useState([]);
    const [movie, setMovie] = useState([]);
    const [news, setNews] = useState([]);
    const [goals, setGoals] = useState([]);

    const donateRef = useRef(null);

    useEffect(() => {
        fetch(`https://notion-api.splitbee.io/v1/page/${'a9a7708ecc9142dc9f60a32f0ea907ac'}`)   // NOTION_PAGE_ID
          .then(res => res.json())
          .then((resJson) => {
            setIntro(resJson);
          });
        
        fetch(`https://notion-api.splitbee.io/v1/page/${'acdc4e96c5f94525b4e7fa5ed6bfcdae'}`)
          .then(res => res.json())
          .then((resJson) => {
            setDonation(resJson);
          });
        
        // Goals of NPO
        const id_1 = "041202ea8d1c4a9c95e845549db2c546"
        fetch(`https://notion-api.splitbee.io/v1/page/${id_1}`)
        .then(res => res.json())
        .then((resJson) => {
            console.log( 'resJson', resJson, Object.values(resJson) )
            const values = Object.values(resJson)

            try {
                console.log(values[1].value.properties.title[0])
                console.log(values[2].value.properties.title[0])

                setGoals([
                    values[1].value.properties.title[0],
                    values[2].value.properties.title[0],
                    values[3].value.properties.title[0],
                    values[4].value.properties.title[0],
                ])
            } catch (err) {
                
            }           
        });

        // Movie
        const id_2 = "4e9dc991bfb54b5aa3c4251a6153f009"
        fetch(`https://notion-api.splitbee.io/v1/page/${id_2}`)
        .then(res => res.json())
        .then((resJson) => {
            console.log( 'resJson', resJson, Object.values(resJson) )
            const values = Object.values(resJson)

            try {
                console.log(values[1].value.properties.title[0][0], values[1].value.properties.title[1][1][0][1])
                console.log(values[2].value.properties.title[0][0], values[2].value.properties.title[1][1][0][1])

                let list = []
                values.map((o,i)=>{
                    if(i>0) {
                        try {
                            list.push({
                                title: values[i].value.properties.title[0][0], 
                                url: values[i].value.properties.title[1][1][0][1],
                                titleColor: "#1d1d1b",
                            })   
                        } catch (e) {}                         
                    }
                })
                setListAds(list)
                console.log( 'listAds', list )
            } catch (err) {
                console.log(err)
            }           
        });

        // News
        const id_3 = "a1222a6999924cb7905e1c8e611273c6"
        fetch(`https://notion-api.splitbee.io/v1/page/${id_3}`)
        .then(res => res.json())
        .then((resJson) => {
            console.log( 'resJson', resJson, Object.values(resJson) )
            const values = Object.values(resJson)

            try {
                console.log(values[1].value.properties.title[0][0], values[1].value.properties.title[1][1][0][1])
                console.log(values[2].value.properties.title[0][0], values[2].value.properties.title[1][1][0][1])

                let list = []
                values.map((o,i)=>{
                    if(i>0) {
                        try {
                            list.push({
                                title: values[i].value.properties.title[0][0], 
                                url: values[i].value.properties.title[1][1][0][1],
                                titleColor: "#1d1d1b",
                            })   
                        } catch (e) {}                         
                    }
                })
                setListNews(list)
                console.log( 'listNews', list )
            } catch (err) {
                console.log(err)
            }           
        });
        
        const DISQUS_SCRIPT = 'disq_script'
        const sd = document.getElementById(DISQUS_SCRIPT)
        if (!sd) {
            var disqus_config = function() {
                this.page.url = "https://www.kafink.org/"
                this.page.identifier = "kafink-id"
            }

            const d = document
            const s = d.createElement('script')
            s.src = 'https://https-www-kafink-org.disqus.com/embed.js' // REPLACE THIS LINE WITH YOUR DISQUS LINE
            s.id = DISQUS_SCRIPT
            s.async = true
            s.setAttribute('data-timestamp', +new Date())

            d.body.appendChild(s)
        } else {
            window.DISQUS.reset({
                reload: true,
                config: disqus_config,
            })
        }

    }, [])

    return (
        <div className={classes.root}>
            <Grid container className={classes.mainContainer}>
            <Container className="fixed-container" style={{padding: "20px"}}>
            <Grid container className={classes.cardContainer}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ flexGrow: 1 }} className={classes.main}>
                        <Box className={classes.kafink}>
                            <h1 style={{fontSize: 40, fontWeight: 'bold', marginBottom: 20}}>
                                KAFINK <span style={{color: "#3c9dcd", fontSize: "14px"}}>(Korean American Football in Korea)</span>
                            </h1>
                            <div className={classes.kafink_content}>
                            <NotionRenderer
                                blockMap={intro}
                                fullPage={true}
                            />
                            </div>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{paddingLeft: 20}}>
                    <Box sx={{ flexGrow: 1 }} className={classes.main} style={{padding:10}}>
                        <ReactPlayer url={"https://youtu.be/6so4M3pY4Vs"} width="100%"
                            light={<img src='/thumbnail.jpg' width="100%"/>} 
                        />
                    </Box>
                </Grid>
            </Grid>
            </Container>
            </Grid>
            
            <Grid container style={{marginTop: 20, minHeight: 400, padding: "20px"}}>
            <Container className="fixed-container">
                <Box sx={{ display: 'flex' }} className={classes.ribbonContainer}>
                    <Box sx={{ flexGrow: 1 }} className={classes.ribbon}>
                    <div>Goals of this NPO</div>
                    </Box>
                </Box>

                <Grid container className={classes.cardContainer}>
                <Grid item xs={12} md={6}>
                    <Card sx={{  flexGrow: 1 }} style={{margin: "0 30px 20px"}}>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://www.opromouthguards.com/media/magefan_blog/OPRO1-amfoot.jpg"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" style={{fontWeight: "bold"}}>
                                {goals[0]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {goals[1]}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Share</Button>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ flexGrow: 1 }}>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://media.gq-magazine.co.uk/photos/5d13ad354113b55f8e46adb7/16:9/w_2560%2Cc_limit/New-England-Patriots-06-GQ-18Oct17_getty_b.jpg"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" style={{fontWeight: "bold"}}>
                                {goals[2]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {goals[3]}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Share</Button>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                </Grid>
            </Container>
            </Grid>
            
            <RenderVideo bgColor="#eee" title="American Football in Korean Ads" list={listAds}></RenderVideo>
            <RenderVideo bgColor="#fff" title="Korean Football News" list={listNews}></RenderVideo>
            <RenderImage bgColor="#eee" title="Best Photos" list={list1}></RenderImage>            
            
            <Container id="donate" className="fixed-container">
                <Box className={classes.titleContainer} display="flex" justifyContent="space-between">
                    <Box className={classes.title}>
                        How to participate 
                    </Box>
                    <div className={classes.donate}>Donate</div>
                </Box>
                <Card>
                    <CardContent>
                        {/* <Typography gutterBottom variant="h7" component="div">
                        <div>Bank: PNC</div>
                        <div>Account Number: 4711227998</div>
                        <div>Routing Number: 071921891</div>
                        <div>details to be added</div>
                        </Typography> */}
                        <NotionRenderer
                            blockMap={donation}
                            fullPage={true}
                        />
                    </CardContent>
                </Card>

                <Box className={classes.titleContainer}>
                    <Box className={classes.title}>
                        Contact Information
                    </Box>
                </Box>
                <Box mb="30px">
                <Typography gutterBottom variant="h7" component="div">
                    george@kafink.org 
                </Typography>
                </Box>
                <Box>
                    Please Login to leave a message.
                </Box>
                {/* <div id="disqus_thread"></div> */}
                <DiscussionEmbed
                    shortname={disqusShortname}
                    config={disqusConfig}
                    />
            </Container>
        </div>
    );
}

const RenderImage = ({bgColor, title, list}) => {
    const classes = useStyles();

    return (
        <Grid container style={{backgroundColor: bgColor, minHeight: 400, padding: 20,}}>
            <Container className="fixed-container">
                <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                    <Box sx={{ flexGrow: 1 }} className={classes.title1}>
                    <div>{title}</div>
                    </Box>
                </Box>

                <Grid container className={classes.cardContainer}>
                {list.map((o,i)=>(
                <Grid item xs={12} md={4} key={'image-'+i}>
                    <Card sx={{  flexGrow: 1 }} style={{margin: "0 30px 20px"}}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={o.image}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {o.title}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                ))}
                
                </Grid>
            </Container>
            </Grid>
    )
}

const RenderVideo = ({bgColor, title, list}) => {
    const classes = useStyles();

    return (
        <Grid container style={{backgroundColor: bgColor, minHeight: 400, padding: "20px 0",}}>
            <Container className="fixed-container">
                <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                    <Box sx={{ flexGrow: 1 }} className={classes.title1}>
                    <div>{title}</div>
                    </Box>
                </Box>

                <Grid container className={classes.cardContainer}>
                {list && list.map((o,i)=>(
                <Grid item xs={12} md={4} key={'video-'+i}>
                    <Card sx={{  flexGrow: 1 }} style={{margin: "0 30px 20px"}}>
                        <ReactPlayer url={o.url} width="100%" />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                {o.title}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                ))}
                
                </Grid>
            </Container>
            </Grid>
    )
}

export default Home;

