
export const initialState = {
};

export const KAFINKContextReducer = (state, action) => {
	// console.log('[reducer].....', deepcopy(state), deepcopy(action));
	const { type, data } = action;

	if (type === 'loading') {
		return {
			...state,
			loading: data,
		}
	}

	return state;
}