import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    navBar: {
        paddingLeft: 0,
    },
    donate: {
        background: "#da291c",
        color: "#fff",
        padding: "10px 20px",
        fontSize: 22,
        borderRadius: 10,
        fontWeight: "bold",
        cursor: "pointer",
    },
    content: {
        marginTop: 20,
        flexGrow: 1,
        float: "left",
        textAlign: "center",
        fontSize: "20px",
    },
    cursor: {
        cursor: "pointer",
    },
    titleContainer: {
        display: 'flex',
        flex: 1,
        cursor: "pointer",
    },
    title: {
        fontSize: "1rem",
        color: "black",
        marginLeft: theme.spacing(4),
        fontWeight: "bold",
    },
    img: {
        marginTop: '4px',
        // marginRight: '150px',
        width: '186px',
        height: '65px',
        cursor: "pointer",
    },
    sidebar: {
        float: "left",
        width: "230px"
    },
    noti: {
        '& .MuiPopover-paper': {
            boxShadow: 'none',
        },
        '& .MuiList-padding': {
            padding: 0
        }
    },
    MuiTableHead: {
        display: 'table',
        width: '100%',
    },
    MuiTableBody: {
        display: 'block',
        height: '307px',
        overflowY: 'auto',
    },
    table: {
        '& th': {
            padding: '10px 0',
            textAlign: 'center',
            '&:nth-child(1)': {
                width: '40px',
            },
            '&:nth-child(2)': {
                width: '260px',
            },
            '&:nth-child(3)': {
                width: '42px'
            },
            // '& th:nth-child(1)': {
            //     width: '45px'
            // },
        },
        '& td': {
            padding: '10px 0',
            textAlign: 'center',
            '&:nth-child(1)': {
                width: '40px',
            },
            '&:nth-child(2)': {
                maxWidth: '260px',
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '0 10px'
            },
            '&:nth-child(3)': {
                width: '42px'
            },
        }
    },
    popup: {
        '& .MuiPaper-rounded': {
            border: '3px solid #9cb1b0',
            backgroundColor: '#f0fcfc',
            borderRadius: '20px'
        }
    },
    menuItem:{
        fontFamily: "IBM Plex Sans KR",
        fontWeight: "600",
    }
}));

const Header = ({history}) => {
    const classes = useStyles();

    const donate = () => {
        const divElement = document.getElementById('donate');
        divElement.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Container className="fixed-container">
                    <Toolbar className={classes.navBar} id="navbar">
                    <Box display="flex" width="100%">
                        <Box>
                            <Typography variant="h6" noWrap>
                                <img src="/logo.jpg" className={classes.img} onClick={() => history.push(`/`)}/>
                            </Typography>
                        </Box>
                        <Box flex={1} display="flex" justifyContent="end" alignItems="center" style={{paddingRight: "50px"}}>
                            {/* <img src="/teams1.jpg" style={{width: 400}}></img> */}
                        </Box>
                        <Box display="flex" alignItems="center">
                            <div className={classes.donate} onClick={() => donate()}>Donate</div>
                        </Box>
                    </Box>
                    </Toolbar>
                </Container>
            </AppBar>

        </>
    );
}

export default Header;