import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: 'relative',
    },
    appBar: {
        position: 'absolute',
        // left: '0px',
        // bottom: '0px',
        marginTop: '295px',
        marginBottom: '0px',
        zIndex: 0,
        color: 'rgb(98 98 98)',
        backgroundColor: '#fafafa',

    },
    companyName: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '30px',
        // marginBottom: '-35px'
    },
    company: {
        fontSize: '0.5rem',
        marginBottom: '35px'
    },
    img: {
        width: '100px',
        marginRight: '20px',
        borderRadius: '2px'
    },
}));

const Footer = (props) => {
    const classes = useStyles();

    return (
        <>
        <AppBar position="static" color="primary" className={clsx("fixed-container", classes.appBar)}>
          <Container>
            <Box>
                <Typography className={classes.companyName}>
                {/* KAFINK */}
                </Typography>
                {/* <img src="/img/logoCompany.png" className={classes.img}/> */}
            </Box>
            <Box style={{textAlign: 'right'}}>
                <Typography className={classes.company}>
                KAFINK | Jaehyoung George Cho | <br/>
                Phone: +1-949-278-1367 | #: 123-45-76554<br/>
                Email: george@kafink.org<br/>
                Copyright © Kafink All rights reserved
                </Typography>
            </Box>
          </Container>
        </AppBar>            
        </>
    );
}

export default Footer;