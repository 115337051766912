import React, { useState, useRef, useEffect, createContext, useReducer, useMemo } from 'react'
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Header from "./components/header/Header";
import Section from './components/Section';
import Bottom from "./components/header/Footer";
import { initialState, KAFINKContextReducer } from './components/context/KAFINKContextReducer'

export const KAFINKContext = createContext();

const theme = createTheme({
  typography: {
    fontFamily: `"Spoqa Han Sans Neo", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
  },

  palette: {
    primary: {
      main: '#fff'
    },
  },
  "overrides": {
    MuiSvgIcon: {
      root: {
        // color: '#0e7c80'
      },
      colorPrimary: {
        // color: 'black'
      },
      colorSecondary: {
        // color: '#fff'
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#0e7c80',
        color: '#fff',
        '& > *': {
          color: '#fff'
        }
      },
    },
  },
});

function App() {
  const [state, dispatch] = useReducer(KAFINKContextReducer, initialState);
  const {  } = state;
  const KAFINKContextValue = useMemo(() => ({
    dispatch
  }),
    []);

  
  return (
    <div className="App">
      <KAFINKContext.Provider value={KAFINKContextValue}>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <Section />
          <Bottom />
        </Router>
      </ThemeProvider>
      </KAFINKContext.Provider>      
    </div>
  );
}

export default App;
