import { makeStyles } from '@mui/styles';
import BeatLoader from "react-spinners/BeatLoader";
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    loading: {
        position: 'fixed',
        top: '380px',
        left: '46%',
        right:'43%',
        minWidth: '140px'
    },
}));

const Loader = ({loading}) => {
    const classes = useStyles();

    return (
        <Box className={classes.loading}>
            <BeatLoader color={'#0e7c80'} loading={loading} size={40} />
        </Box>
    );
}

export default Loader;